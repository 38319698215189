<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
// import resize from './mixins/resize'

export default {
  // mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '450px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
      console.log(this.chartData)
    },
    setOptions ({ expectedData, actualData, time } = {}) {
      this.chart.setOption({
        xAxis: {
          data: time,
          boundaryGap: true,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#f20023",
              width: 0,
              type: "solid"
            }
          },
          axisLabel: {// 坐标轴刻度标签的相关设置。
            textStyle: {
              fontSize: 12,
              color: '#f20023',
              lineHeight: 20
            },
            // formatter: function (params) {
            //   let newParamsName = ''// 最终拼接成的字符串
            //   const paramsNameNumber = params.length // 实际标签的个数
            //   const provideNumber = 10// 每行能显示的字的个数
            //   const rowNumber = Math.ceil(paramsNameNumber / provideNumber)// 换行的话，需要显示几行，向上取整
            //   /**
            //    * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
            //    */
            //   // 条件等同于rowNumber>1
            //   if (paramsNameNumber > provideNumber) {
            //     /** 循环每一行,p表示行 */
            //     for (let p = 0; p < rowNumber; p++) {
            //       let tempStr = ''// 表示每一次截取的字符串
            //       const start = p * provideNumber // 开始截取的位置
            //       const end = start + provideNumber // 结束截取的位置
            //       // 此处特殊处理最后一行的索引值
            //       if (p === rowNumber - 1) {
            //         // 最后一次不换行
            //         tempStr = params.substring(start, paramsNameNumber)
            //       } else {
            //         // 每一次拼接字符串并换行
            //         tempStr = params.substring(start, end) + '\n'
            //       }
            //       newParamsName += tempStr// 最终拼成的字符串
            //     }
            //   } else {
            //     // 将旧标签的值赋给新标签
            //     newParamsName = params
            //   }
            //   // 将最终的字符串返回
            //   return newParamsName
            // }

          }
        },
        grid: {
          // left: 10,
          // right: 10,
          // bottom: 20,
          // top: 30,
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '4%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#AAA',
              width: 1
            },
            axis: 'auto', // 指示器的坐标轴。
            snap: true
          },
          padding: [5, 10]
        },
        yAxis: {
          axisTick: {
            show: false
          },
          axisLabel: {// 坐标轴刻度标签的相关设置。
            textStyle: {
              fontSize: 12,
              color: '#f20023',
              lineHeight: 20
            }
          }
        },
        legend: {
          data: ['净值']
        },
        animation: false,
        series: [
        {
          name: '净值',
          smooth: true,
          type: 'line',
          itemStyle: {
            normal: {
              color: '#FA8876',
              lineStyle: {
                color: '#FA8876',
                width: 2
              },
              areaStyle: {
                color: '#f3f8ff',
                opacity: 0
              }
            }
          },
          data: actualData,
          // 高亮样式。
          emphasis: {
            itemStyle: {
              // 高亮时点的颜色。
              color: '#FA8876'
            }
          }
        }]
      })
    }
  }
}
</script>
